<template>
  <el-container>
    <el-main>
    <el-form ref="syncForm"  :rules="rules" :model="form" label-width="80px">
      <el-form-item>
        <el-button type="primary" @click="submitForm('syncForm')">同步发布</el-button>
      </el-form-item>
      <el-form-item label="标题"  prop="name">
        <el-input v-model="form.name"   placeholder="请输入2-20字的标题"></el-input>
      </el-form-item>
      <el-form-item label="内容"  prop="content">
        <div  id="main" style="height:100%">
        <mavon-editor v-model="original" @change="change" language="zh-CN"/></div>
      </el-form-item>

    </el-form>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      original:'',
      form: {
        name: '',
        content: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        content: [
          {required: true, message: '请输入内容', trigger: 'blur'},
        ],
      }
    }
  },
  methods: {
    onSubmit() {
      window.syncPost({
        title: this.form.name,
        // desc:'',
        content: this.form.content,
        thumb:'',
      })
      console.log('submit!');
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form.content)
          this.onSubmit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    change(value,render){
      this.form.content = render
    },
  },

}
</script>

<style>

</style>
